import React, { ReactElement } from "react";
import { Link } from "gatsby";
import { GatsbyPageProps } from "@ymcansw-camping/common";
import SchoolsLayout from "../../components/schools-layout";

export default function SchoolsPage(): ReactElement<GatsbyPageProps> {
  return (
    <SchoolsLayout
      isIndex
      meta={{
        title: "School Programs",
        description:
          "Our School Programs are based on a sequential year level model that have at their core our Learning Outcomes Framework that covers three main areas for learning.",
      }}
    >
      <div className="content-block">
        <h2>Our programs</h2>
        <p>
          We encourage discussions focussed on student Learning Outcomes for
          your school&apos;s requirements. From these discussions we can design
          a Training and Education Program best suited to your students&apos;
          needs. Our School Programs are based on a sequential year level model
          that have at their core our Learning Outcomes Framework that covers
          three main areas for learning. They include:
        </p>
      </div>

      <div className="content-block icon-circle-container">
        <h3 className="h5">Potential Learning Outcomes</h3>
        <div className="row">
          <div className="col-xs-12 col-sm-4">
            <div className="icon-circle-block text-center">
              <img
                className="img-fluid"
                src={require("../../images/img/school-personal-growth.jpg")}
                alt="Personal Growth"
              />
              <h4 className="title">Personal Growth</h4>
              <p>
                Build resilience
                <br />
                Reinforce a positive outlook
              </p>
            </div>
          </div>
          <div className="col-xs-12 col-sm-4">
            <div className="icon-circle-block text-center">
              <img
                className="img-fluid"
                src={require("../../images/img/school-community.jpg")}
                alt="Sense of Community"
              />
              <h4 className="title">Sense of Community</h4>
              <p>
                Connect with others
                <br />
                Work as a team
              </p>
            </div>
          </div>
          <div className="col-xs-12 col-sm-4">
            <div className="icon-circle-block text-center">
              <img
                className="img-fluid"
                src={require("../../images/img/school-nature.jpg")}
                alt="Natural World"
              />
              <h4 className="title">Connect with Natural World</h4>
              <p>Appreciate the natural world</p>
            </div>
          </div>
        </div>
      </div>

      <div className="content-block">
        <h3 className="h5">Sequential Programming Model</h3>
        <p>
          Your students can participate in three different types of programs
          that range from Residential programming which is based on site at Camp
          Yarramundi, Introduction to Journey Based Programming that utilises
          both Camp Yarramundi and our Camp Deeimba site or Journey Based
          Programming that can combine camp Yarramundi, Camp Deeimba and the
          surrounding Blue Mountains National Park including Shaw&apos;s Reserve
          that we use as a staging point for both indigenous programming content
          and a paddle down the Nepean River.
        </p>
      </div>

      <div className="content-block box-link-container">
        <div className="row">
          <div className="col-xs-12 col-sm-6">
            <div className="box-link with-image-bg">
              <img
                src={require("../../images/img/spm-residential.jpg")}
                alt="Residential based programs"
              />
              <span>Residential based programs</span>
              <Link className="full-box-link" to="/schools/residential/" />
            </div>
          </div>
          <div className="col-xs-12 col-sm-6">
            <div className="box-link with-image-bg">
              <img
                src={require("../../images/img/spm-values.jpg")}
                alt="Values"
              />
              <span>Values</span>
              <Link
                className="full-box-link"
                to="/schools/learning-framework/"
              />
            </div>
          </div>
        </div>
      </div>
    </SchoolsLayout>
  );
}
